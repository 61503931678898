/*!
 * BackOffice version: 0.0.1
 * Authors: Maikel Enrique Pernía Matos <meperniamatos@gmail.com>
 * Copyright: 2018 Savso.es
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

$body-color: #414040 !default;

$purple: #69356e !default;
$primary: $purple !default;
$primaryLight: #ab7cb6;
$secondary: $primaryLight !default;

$red: #a01127;
$green: #70a83b;
$cyan: #009bdb;

/* Generals */
@import '../node_modules/@ng-select/ng-select/themes/default.theme';
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/ngx-toastr/toastr-bs4-alert.scss';
@import '../node_modules/ng2-daterangepicker/assets/daterangepicker.css';

.toast {
    opacity: 1;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'],
input[type='date'],
input[type='time'],
input[type='url'] {
    border-radius: 5px;
    border: 1px solid #69356e;
    outline: none;
    padding-left: 5px;
    padding-right: 5px;
}

.input-group-text {
    border-color: $primary;
    background: transparent;
}

select {
    border-radius: 5px;
    border: 1px solid #69356e;
    outline: none;
    /* width: 100%; */
    background-color: #ffffff;
    height: 28px;
}

select.form-control,
textarea.form-control {
    border-color: #69356e;
}

.combobox {
    border: 1px solid #69356e;
    width: 100%;
    overflow: hidden;
    background: #fff url('assets/img/backoffice/formulario.svg') no-repeat 100%
        center;
    border-radius: 5px;
    cursor: pointer;
    height: 32px;
    margin-top: 5px;
}

.combobox select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.combobox select:focus {
    outline: none;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding: 0;
}

.ng-select .ng-select-container {
    border-radius: 5px;
    border: 1px solid $primary;
    min-height: 38px;
}
.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    top: 6px;
    padding-bottom: 6px;
}
.ng-select.ng-select-opened > .ng-select-container {
    border-color: $primary $secondary $primary;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $primary;
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
}
.ng-dropdown-panel {
    border-color: $primary;
}
.ng-dropdown-panel.ng-select-bottom {
    border-top-color: $secondary;
}
.ng-dropdown-panel.ng-select-top {
    border-bottom-color: $secondary;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $secondary;
    color: $body-color;
}
.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background-color: $secondary;
    border: 1px solid $primary;
}
.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon.left {
    border-right: 1px solid $primary;
}
.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon:hover {
    background-color: $primary;
}
.ng-select .ng-clear-wrapper .ng-clear:hover {
    color: $red;
}
@font-face {
    font-family: 'Roboto';
    src: local(Roboto Thin), url('assets/fonts/roboto/Roboto-Thin.eot');
    src: url('assets/fonts/roboto/Roboto-Thin.eot?#iefix')
            format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Thin.woff2') format('woff2'),
        url('assets/fonts/roboto/Roboto-Thin.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Roboto';
    src: local(Roboto Light), url('assets/fonts/roboto/Roboto-Light.eot');
    src: url('assets/fonts/roboto/Roboto-Light.eot?#iefix')
            format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Light.woff2') format('woff2'),
        url('assets/fonts/roboto/Roboto-Light.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: local(Roboto Regular), url('assets/fonts/roboto/Roboto-Regular.eot');
    src: url('assets/fonts/roboto/Roboto-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
        url('assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Medium.eot');
    src: url('assets/fonts/roboto/Roboto-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
        url('assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Bold.eot');
    src: url('assets/fonts/roboto/Roboto-Bold.eot?#iefix')
            format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
        url('assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

.bg-green-light {
    background-color: #ab7cb6 !important;
}
.bg-green-dark {
    background-color: #69356e !important;
}
.bg-green {
    background-color: green;
}
.bg-red {
    background-color: red;
}
.text-green {
    color: #69356e;
}
.text-red {
    color: #ee3885;
}

.border-light {
    border: 1px solid #ab7cb6 !important;
}
.border-dark {
    border: 1px solid #69356e !important;
}
.border-dark-left {
    border-left: 1px solid #69356e !important;
}
.border-dark-top {
    border-top: 1px solid #69356e !important;
}
.border-dark-right {
    border-right: 1px solid #69356e !important;
}
.border-dark-bottom {
    border-bottom: 1px solid #69356e !important;
}

.table-bordered {
    border: 1px solid #69356e;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #69356e;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
    border: 1px solid #69356e;
}

.table-none-bordered {
    border: transparent !important;
}

.table-none-bordered th,
.table-none-bordered td {
    border: transparent !important;
}

.table-none-bordered thead th,
.table-none-bordered thead td {
    border-bottom-width: 0px;
    border: transparent !important;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

body {
    margin: 0px;
}

a {
    color: #69356e;
}

a:hover {
    color: #69356e;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: Roboto;
}
/*
     h1 {
         font-size: 6.9vw;
     }

     h2 {
         font-size: 4.9vw;
     }

     h3 {
         font-size: 3.9vw;
     }

     h4 {
         font-size: 3.2vw;
     }

     h5 {
         font-size: 2.5vw;
     }

     h6 {
         font-size: 1.5vw;
     }

     p {
         font-size: 2vmax;
     }
 */

#information h1 {
    font-size: 4.5vw;
    color: #fff;
}
#information h3 {
    font-size: 2.5vw;
    color: #fff;
}
#information {
    margin-top: 8vh;
    margin-top: 5vh;
}
#recover {
    margin-top: 8vh;
}
#loginform {
    margin-top: 20vh;
}

.panel-main-action {
    padding: 20px;
    border: 3px solid #69356e;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

.panel-main-separator {
    border-right: 3px solid #69356e;
}

.panel-main-section {
    margin: 10px;
    margin-top: 4rem;
}
.panel-section {
    margin: 10px 0px 10px 0px;
    padding: 5px;
    background-color: #fff !important;
    border: none;
    color: #000 !important;
}

.container-description {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 15px;
}

.util-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: left;
    align-items: left;
    -ms-flex-pack: left;
    justify-content: left;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 30px;
    padding: 20px;
}
/* Generals end */

/* NavBar */

.navbar-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
}

.navbar-milinea .navbar-nav .nav-link {
    color: white;
}

.navbar-milinea .navbar-brand {
    color: #fff;
}

.navbar-milinea .navbar-brand:focus,
.navbar-milinea .navbar-brand:hover {
    color: #fff;
}

.navbar-milinea .navbar-nav .nav-link {
    color: #fff;
}

.navbar-milinea .navbar-nav .nav-link:focus,
.navbar-milinea .navbar-nav .nav-link:hover {
    color: #fff;
}

.navbar-milinea .navbar-nav .nav-link.disabled {
    color: #fff;
}

.navbar-milinea .navbar-nav .show > .nav-link,
.navbar-milinea .navbar-nav .active > .nav-link,
.navbar-milinea .navbar-nav .nav-link.show,
.navbar-milinea .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-milinea .navbar-toggler {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-milinea .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-milinea .navbar-text {
    color: #fff;
}

.navbar-milinea .navbar-text a {
    color: #fff;
}

.navbar-milinea .navbar-text a:focus,
.navbar-milinea .navbar-text a:hover {
    color: #fff;
}

.navbar-milinea .rounded {
    border-radius: 0.45rem !important;
    font-weight: 600 !important;
    /*    border: 2px solid #dee2e6 !important;*/
}

.navbar-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: 5px;
    width: 100%;
}

.search-text {
    width: 90%;
    border: 1px solid #69356e;
    border-radius: 0.45rem 0.1rem 0.1rem 0.45rem !important;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
}

.nav-btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: right;
    align-items: right;
    -ms-flex-pack: right;
    justify-content: right;
    margin-top: -55px;
    right: 0;
    left: auto;
    float: right;
}

.notification {
    min-height: 20px;
    min-width: 20px;
    background-color: red;
    color: #fff;
    border-radius: 50%;
    font-size: 9px;
    font-weight: 700;
    padding: 5px;
    line-height: 20px;
    text-align: center;
}

#myTabContent {
    margin-bottom: 160px;
}

/* NavBar end */

/* NavBar Button */
.btn-navbar {
    color: #fff;
    background-color: #69356e;
    border-color: #69356e;
    border-radius: 0.1rem 0.65rem 0.65rem 0.1rem !important;
}

.btn-navbar:hover {
    color: #69356e;
    background-color: #afe9dd;
    border-color: #69356e;
}

.btn-navbar:focus,
.btn-navbar.focus {
    box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.5);
}

.btn-navbar.disabled,
.btn-navbar:disabled {
    background-color: #69356e;
    border-color: #afe9dd;
}

.btn-navbar:not([disabled]):not(.disabled):active,
.btn-navbar:not([disabled]):not(.disabled).active,
.show > .btn-navbar.dropdown-toggle {
    color: #fff;
    background-color: #afe9dd;
    border-color: #afe9dd;
    box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.5);
}

/* NavBar Button end */

/* */

.btn-outline-accept {
    color: #69356e;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

.btn-outline-accept:hover {
    color: #69356e;
    background-color: #fff;
    border-color: #69356e;
}

.btn-outline-accept:focus,
.btn-outline-accept.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-accept.disabled,
.btn-outline-accept:disabled {
    color: #69356e;
    background-color: transparent;
}

.btn-outline-accept:not([disabled]):not(.disabled):active,
.btn-outline-accept:not([disabled]):not(.disabled).active,
.show > .btn-outline-accept.dropdown-toggle {
    color: #69356e;
    background-color: #fff;
    border-color: #69356e;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

/* */

/* button accept color: #69356e;  hover: #afe9dd; */

.btn-accept {
    color: #fff;
    background-color: #69356e;
    border-color: #69356e;
    border-radius: 0.45rem;
}

.btn-accept:hover {
    color: #fff;
    background-color: #502954;
    border-color: #48244c;
}

.btn-accept:focus,
.btn-accept.focus {
    box-shadow: 0 0 0 0.2rem rgba(#502954, 0.5);
}

.btn-accept.disabled,
.btn-accept:disabled {
    background-color: #69356e;
    border-color: #69356e;
}

.btn-accept:not([disabled]):not(.disabled):active,
.btn-accept:not([disabled]):not(.disabled).active,
.show > .btn-accept.dropdown-toggle {
    color: #fff;
    background-color: #502954;
    border-color: #502954;
    box-shadow: 0 0 0 0.2rem rgba(#502954, 0.5);
}
/* button accept end */

/* button reject color: #ee3885; hover: #ff80b2; */

.btn-reject {
    color: #fff;
    background-color: #ee3885;
    border-color: #ee3885;
    border-radius: 0.45rem;
}

.btn-reject:hover {
    color: #fff;
    background-color: #ff80b2;
    border-color: #ee3885;
}

.btn-reject:focus,
.btn-reject.focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 33, 56, 0.5);
}

.btn-reject.disabled,
.btn-reject:disabled {
    background-color: #ee3885;
    border-color: #ee3885;
}

.btn-reject:not([disabled]):not(.disabled):active,
.btn-reject:not([disabled]):not(.disabled).active,
.show > .btn-reject.dropdown-toggle {
    color: #fff;
    background-color: #ff80b2;
    border-color: #ff80b2;
    box-shadow: 0 0 0 0.2rem rgba(170, 33, 56, 0.5);
}

/* button reject end */

/* button register */

.btn-register {
    color: #69356e;
    background-color: #e1fffd;
    border-color: #e1fffd;
    border-radius: 0.45rem;
}

.btn-register:hover {
    color: #69356e;
    background-color: #e1fffd;
    border-color: #e1fffd;
}

.btn-register:focus,
.btn-register.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-register.disabled,
.btn-register:disabled {
    background-color: #69356e;
    border-color: #69356e;
}

.btn-register:not([disabled]):not(.disabled):active,
.btn-register:not([disabled]):not(.disabled).active,
.show > .btn-register.dropdown-toggle {
    color: #69356e;
    background-color: #e1fffd;
    border-color: #e1fffd;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

/* end button register */

/* button circle */
.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-md {
    width: 40px;
    height: 40px;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 1.33;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

/* button circle end */

/* Header */

header {
    padding-top: 75px;
}

.header-content {
    margin-top: 10px;
    margin-left: 15px;
    padding: 10px;
}

.caption-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 30px;
    padding: 5px;
}

/* Header end */

/* Main */

.container-content {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 160px;
}

.container-content1 {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 45px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
    color: #fff;
    background-color: #69356e;
}

/* Tab Pages */
.nav-tabs {
    border-bottom: 1px solid #69356e;
}

.nav-tabs .nav-item {
    margin-left: 0px;
}

.nav-tabs .nav-link {
    border: 1px solid #69356e;
    border-top-left-radius: 0.65rem;
    border-top-right-radius: 0.65rem;
    background-color: #ab7cb6;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #69356e;
    background-color: #69356e;
    color: white;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: white;
    background-color: #69356e;
    border-color: #69356e;
}

/* Tab Pages end */

/* tables */
.table-head {
    text-align: center;
    font-weight: bolder;
}
/* tables end */

/* Main end */

/* Footer */

footer {
    background-color: #f2f5f8;
    border-top: 1px solid #69356e !important;
    text-align: center;
    margin-top: 50px;
    font-size: 12px;
}

.container-footer {
    padding: 8px 5px 8px 5px;
}

.credits {
    color: #555;
}

.credits a {
    color: #69356e;
}
/* Footer end */

/* components */

.list-group-item {
    position: relative;
    display: block;
    padding: 0.25rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: none;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #69356e;
    border-radius: 0.25rem;
}

.card-header {
    padding: 0.55rem 0.55rem;
    margin-bottom: 0;
    background-color: #ab7cb6;
    border-bottom: 1px solid #69356e;
    color: white;
}

.card-header-title {
    color: white;
    margin: 0px;
    padding: 0px;
}

.card-header-subtitle {
    color: #000;
    margin: 0px;
    padding: 0px;
}

.card-title {
    margin-bottom: 0.35rem;
    color: #000;
}

.card-subtitle {
    margin-top: -0.35rem;
    margin-bottom: 0;
    color: rgb(180, 175, 175);
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.5rem;
}

a.miLineaFontColor {
    color: #69356e !important;
}
a.miLineaFontColor:hover {
    cursor: pointer;
    text-decoration: underline !important;
}
/* components end*/
@media (min-width: 992px) {
    .big-modal .modal-lg {
        max-width: 1200px;
    }
}
.step {
    &-label {
        color: #69356e !important;
        font-size: 70%;
        padding-left: 10px;
    }
}

.table input {
    width: 100%;
}

.table-hover tbody tr.tr-no-hover:hover {
    color: inherit;
    background-color: transparent;
}

.card-header-title {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.card-header-title span {
    font-size: 80%;
    cursor: pointer;
    font-weight: normal;
}

.card-body .list-group-item span {
    padding-left: 30px;
    word-break: break-all;
}

.link--dotted {
    color: #69356e;
    text-decoration: none;
    border-bottom: 1px dotted rgba(#69356e, 0.75);

    &:hover {
        text-decoration: none;
    }
}

.modal-wide {
    .modal-dialog {
        max-width: 95%;
    }
}

.table-hover tbody tr.without_hover:hover {
    background-color: transparent;
}

.table-hover tbody tr.without_hover td {
    border-top-color: transparent;
}

.cdx-benki-simple-image {
    .cdx-loader {
        min-height: 200px;
    }

    .cdx-input {
        margin-top: 10px;

        &[contentEditable='true'][data-placeholder]:empty::before {
            position: absolute;
            content: attr(data-placeholder);
            color: #707684;
            font-weight: normal;
        }
    }

    img {
        max-width: 100%;
        vertical-align: bottom;
    }

    &__picture {
        cursor: pointer;
        position: relative;

        &--stretched img {
            max-width: none;
            width: 100%;
        }
    }
}

.b_img_fluid {
    max-width: 100%;
    height: auto;
}
.b_img_full {
    width: 100%;
    height: auto;
}
